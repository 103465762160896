import pathAfterLogin from "./pathAfterLogin";
import {Cookie} from "services/cookie/CookieSigned";
export const TOKEN_KEY = "@beedookey";
export const REFRESH_TOKEN_KEY = "@beedoorefreshkey"
export const REMEMBER_ME = "@beedooremember"
export const BEEDOO_CONFIG = '@beedooconfigkey'
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token, refreshToken, remember) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REMEMBER_ME, remember);
  localStorage.removeItem("ACCEPTY_PRIVACY")
  localStorage.removeItem("NEED_NEW_PASSWORD")
  localStorage.removeItem("SUCCESS_CHANGE_PASSWORD")
};
export const logout = () => {
  localStorage.clear()
};

function getSubdomain() {
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "dev";

  if (parts.length >= 3) {
    subdomain = parts[0];
  }

  return subdomain
}

export const SUBDOMAIN = getSubdomain();
